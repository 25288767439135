var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"LandingPage"}},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"carousel slide",attrs:{"id":"demo","data-ride":"carousel"}},[_c('div',{staticClass:"carousel-inner"},_vm._l((_vm.bammerImg),function(Img,index){return _c('div',{key:index,staticClass:"carousel-item",class:index == 0 ? 'active' : ''},[(Img.url)?_c('a',{attrs:{"href":Img.url,"target":"_blank"}}):_vm._e(),_vm._v(" "),_c('img',{staticClass:"pcImg",attrs:{"src":Img.desktop_image_path}}),_vm._v(" "),_c('img',{staticClass:"isoImg",attrs:{"src":Img.mobile_image_path,"alt":""}})])}),0),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"searchRic shadow"},[_c('input',{attrs:{"type":"text","placeholder":((_vm.$t('hp.SW')) + " / " + (_vm.$t('hp.Un')))},domProps:{"value":_vm.listAnUnderlyingsText},on:{"input":function($event){return _vm.listAnUnderlyingsSeek($event)}}}),_vm._v(" "),_vm._m(2),_vm._v(" "),(_vm.listAnUnderlyingsIf)?_c('ul',_vm._l((_vm.listAnUnderlyingsData),function(nri,nriindex){return _c('li',{key:nriindex,on:{"click":function($event){return _vm.listAnUnderlyingsClick(nri)}}},[_vm._v("\n                    "+_vm._s(nri.name)+"\n                ")])}),0):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row",staticStyle:{"justify-content":"center"}},_vm._l((_vm.Decimal),function(data,index){return _c('li',{key:index,staticClass:"libox",class:index == 0
                    ? ''
                    : index == 3
                        ? 'd-none d-lg-block'
                        : index == 4
                            ? 'd-none d-lg-block'
                            : index == 5
                                ? 'd-none d-lg-block'
                                : ''},[_c('div',{staticClass:"box shadow"},[_c('div',{staticClass:"head"},[_c('span',[_vm._v(_vm._s(data.name))]),_vm._v(" "),_c('button',{staticClass:"btn",attrs:{"type":"button"}},[_vm._v("\n                            "+_vm._s(_vm.$t("hp.Manage"))+"\n                            "),_c('ul',_vm._l((_vm.DecimalMenu),function(data2,index2){return _c('li',{key:index2,on:{"click":function($event){return _vm.componentsShow(data2, index)}}},[_vm._v("\n                                    "+_vm._s(data2.name)+"\n                                ")])}),0)])]),_vm._v(" "),_c('div',{staticClass:"content"},[(data.id == 'lm')?_c('Matrix',{attrs:{"MAindex":index}}):_vm._e(),_vm._v(" "),(data.id == 'dh')?_c('Highlights'):_vm._e(),_vm._v(" "),(data.id == 'wm')?_c('Movers',{attrs:{"Mindex":index}}):_vm._e(),_vm._v(" "),(data.id == 'ftse')?_c('FTSE',{attrs:{"Findex":index}}):_vm._e(),_vm._v(" "),(data.id == 'new')?_c('New'):_vm._e(),_vm._v(" "),(data.id == 'ew')?_c('Expired'):_vm._e(),_vm._v(" "),(data.id == 'ww')?_c('Watch',{attrs:{"Windex":index}}):_vm._e(),_vm._v(" "),(data.id == 'wb')?_c('Bulletin',{attrs:{"Bindex":index}}):_vm._e(),_vm._v(" "),(data.id == 'Te')?_c('Telegram'):_vm._e()],1)])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"carousel-control-prev",attrs:{"href":"#demo","data-slide":"prev"}},[_c('span',{staticClass:"carousel-control-prev-icon"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"carousel-control-next",attrs:{"href":"#demo","data-slide":"next"}},[_c('span',{staticClass:"carousel-control-next-icon"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"magnifying_glass"},[_c('img',{attrs:{"src":require("../../../public/image/magnifying_glass.png"),"alt":""}})])}]

export { render, staticRenderFns }