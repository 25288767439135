export default {
    name: "Matrix",
    created () {
        this.$nextTick(function () {
            this.GetUnderlyingList()
            this.$$.inputEmpty('.search')
        });
    },
    mounted () {

    },
    data () {
        return {
            // list ric的数据
            ricData: [],
            ricDataDataDackups: [],
            search: '',
            ifSearchUl: false,
            // 列表数据
            MatrixData: [],
            //  转换之后的数据
            theirdata: [{
                name: this.$t('WSe.EP'),
                price: ''
            },
            {
                name: this.$t('WSe.ER'),
                price: ''
            },
            {
                name: this.$t('hp.Ex'),
                price: ''
            },
            {
                name: this.$t('int.EG'),
                price: ''
            }
            ],
            ticker: '',
            underlyingRic: '',
            dsply_nmll: '',
            dsplyRic: '',
            livematrix: [],
            underlying_bid: '',
            publishTime: '',
            issuer_mibb: false,
            MAinput: '',
            reminder: true,
            typeId: ''
        };
    },
    props: ['MAindex'],
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取 Daily Highlights ric 数据
        GetDeployedWarrantOfTheDay: function () {
            let ricArr = []
            $.ajax({
                type: "post",
                url: this.$$.mibb + "GetDeployedWarrantOfTheDay",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        this.search = `${data.text[0].underlying_code} (${data.text[0].underlying_name})`
                        this.GetRICListByUnderlying(data.text[0].underlying_name)
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 underlying ric
        GetUnderlyingList: function () {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetUnderlyingList",
                data: { token: "webkey", issuers: 'MST' },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        this.ricData = data.underlying_name
                        this.ricDataDataDackups = data.underlying_name
                        // this.GetDeployedWarrantOfTheDay()
                        this.GetRICListByUnderlying(data.underlying_name[0].underlying_ric)
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 underlying 的数据
        GetRICListByUnderlying: function (underlying, ifv) {
            let _this = this
            let arrRic = []
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetRICListByUnderlying",
                data: { token: "webkey", issuers: 'MST', underlying: underlying },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        $.each(data.ric, function (i, c) {
                            arrRic.push(c.ric)
                        })
                        this.getRicdatas(arrRic, ifv)
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        /*获取当前ric数据*/
        getRicdatas: function (ric, ifv) {
            this.MatrixData = []
            let _this = this
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetMarketData",
                data: { token: "webkey", ric: ric.toString(), type: "warrantdata" },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        $.each(ric, function (i, c) {
                            _this.MatrixData.push(data[c])
                        })
                        if (ifv) {
                            $('.tspl_table' + this.MAindex).show("slow")
                            $('.switch_table' + this.MAindex).hide("slow")
                        } else {
                            this.GetPriceMatrixData(this.MatrixData[0].ric)
                        }
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        /*获取当前ric数据*/
        GetPriceMatrixData: function (ric) {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetPriceMatrixData",
                data: { token: "webkey", ric: ric },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        this.theirdata[0].price = data.ric_data.exercise_price !== "N/A" ? this.$$.curreryRetrun(data.ric_data.underlying_curr) + " " + this.$$.formatNumber(data.ric_data.exercise_price, 4, 0) : "N/A"
                        this.theirdata[1].price = this.$$.formatNumber(data.ric_data.conv_ratio, 4, 0)
                        this.theirdata[2].price = data.ric_data.maturity
                        this.theirdata[3].price = data.ric_data.effective_gearing !== "N/A" ? this.$$.formatNumber(data.ric_data.effective_gearing, 1, 0) + " x" : "N/A"
                        this.ticker = data.ric_data.underlying_ticker
                        this.underlyingRic = data.ric_data.underlying_ric
                        this.underlying_bid = data.ric_data.underlying_bid
                        this.dsply_nmll = data.ric_data.dsply_nmll
                        this.typeId = data.ric_data.type
                        if (data.reliable_livematrix == 0 && this.$$.isMAYBANK(data.ric_data.issuer_name)) {
                            this.issuer_mibb = true
                            $('#reliableLivematrix').modal('show')
                        } else {
                            this.issuer_mibb = false
                            $.each(data.livematrix, (l, d) => {
                                d.tableArr = []
                                for (let i = 1; i <= 5; i++) {
                                    d.tableArr.push({
                                        bid: d['bid' + i],
                                        date: new Date(d['date' + i]).toUTCString().split(' ')[1] + '-' + new Date(d['date' + i]).toUTCString().split(' ')[2]
                                    })
                                }
                            })
                            this.livematrix = this.isRepeat(
                                data.livematrix,
                                data.ric_data.underlying_bid
                            )
                            this.dsplyRic = data.ric_data.ric
                            this.publishTime = data.livematrix.length === 0 ? '' : data.livematrix[0].publish_time
                            this.$forceUpdate();
                        }

                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        timeLivematrixData: function (D) {
            this.tableArr = []
            $("#Matrix")
                .find(".ChartButtonBox")
                .find("div")
                .eq(D - 1)
                .find(".btn")
                .addClass("mark");
            $("#Matrix")
                .find(".ChartButtonBox")
                .find("div")
                .eq(D - 1)
                .siblings("div")
                .find(".btn")
                .removeClass("mark")
            if (D == 1) {
                this.tableArr[0] = {
                    livematrix: this.isRepeat(
                        this.liveArr5,
                        this.underlying_bid
                    )
                }
            }
            if (D == 2) {
                for (let i = 0; i < D; i++) {
                    this.tableArr[i] = {
                        livematrix: this.isRepeat(
                            this['liveArr' + (i + 4)],
                            this.underlying_bid
                        )
                    }
                }

            }
            if (D == 3) {
                for (let i = 0; i < D; i++) {
                    this.tableArr[i] = {
                        livematrix: this.isRepeat(
                            this['liveArr' + (i + 3)],
                            this.underlying_bid
                        )
                    }
                }
            }
            if (D == 4) {
                for (let i = 0; i < D; i++) {
                    this.tableArr[i] = {
                        livematrix: this.isRepeat(
                            this['liveArr' + (i + 2)],
                            this.underlying_bid
                        )
                    }
                }
            }
            if (D == 5) {
                for (let i = 0; i < D; i++) {
                    this.tableArr[i] = {
                        livematrix: this.isRepeat(
                            this['liveArr' + (i + 1)],
                            this.underlying_bid
                        )
                    }
                }
            }
            this.$forceUpdate()
            console.log(this.tableArr);
        },
        // 搜索相应数据
        searchInput: function (e) {
            this.MAinput = e.target.value
            if (e.target.value.length == 0) {
                this.ricData = this.ricDataDataDackups
                this.ifSearchUl = false
            } else {
                this.ricData = this.ricDataDataDackups.filter((item) => {
                    let name = `${item.underlying_code} (${item.underlying_name})`
                    return name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 || e.target.value.toLowerCase().indexOf(name.toLowerCase()) >= 0
                })
                this.ifSearchUl = true
            }
        },
        searchClick: function (data) {
            this.search = `${data.underlying_code} (${data.underlying_name})`
            this.GetRICListByUnderlying(data.underlying_ric, true)
            this.MAinput = ''
            this.ifSearchUl = false
        },
        // 转换显示模式
        switchPattern: function (ric) {
            this.GetPriceMatrixData(ric)
            $('.tspl_table' + this.MAindex).hide("slow")
            $('.switch_table' + this.MAindex).show("slow")
        },
        // 判断数组下一个是否与上一个相等
        isRepeat: function (arr, bid) {
            let hash = {};
            let ifv = false
            for (let i in arr) {
                hash[arr[i].bid] = true;
                if (arr[i].underlying_bid == bid) {
                    arr[i].bidIfv = true;
                } else {
                    arr[i].bidIfv = false;
                }
            }
            return arr
        },
        onOff: function (index) {
            $('.switch_table' + this.MAindex).find('.one').toggle()
            $('.switch_table' + this.MAindex).find('.whole').toggle()
            if ($('.switch_table' + this.MAindex).find('.onOff').hasClass('bi-chevron-double-right')) {
                $('.switch_table' + this.MAindex).find('.onOff').removeClass('bi-chevron-double-right');
                $('.switch_table' + this.MAindex).find('.onOff').addClass('bi-chevron-double-left');
                this.reminder = false

            } else {
                $('.switch_table' + this.MAindex).find('.onOff').removeClass('bi-chevron-double-left');
                $('.switch_table' + this.MAindex).find('.onOff').addClass('bi-chevron-double-right');
                this.reminder = true
            }
        }
    }
};