export default {
    name: "Telegram",
    created() {
        this.$nextTick(function() {

        });
    },
    mounted() {

    },
    data() {
        return {
            telegram: 1,
        };
    },
    props: ['JudgeIos'],
    computed: {

    },
    watch: {

    },
    methods: {

    }
};