import Chart2 from "../Chart2/Chart2.vue";
export default {
    name: "Watch",
    created () {
        this.$nextTick(function () {
            this.GetDeployedMarketInsightsAlways();
        });
    },
    mounted () { },
    props: ["Windex"],
    data () {
        return {
            optionData: [],
            optionDataText: "",
            contentData: [],
            emailContentData: [],
            textName: "",
            fvData: true,
            email: "",
            ModalText: "",
            Disabled: true,
            checkCaptch: false,
            checkedTo: false,
            Eeceive: 0,
            recapctchacode: "",
            mouse: false
        };
    },
    computed: {},
    watch: {},
    methods: {
        GetDeployedMarketInsightsAlways: function () {
            $.ajax({
                type: "POST",
                dataType: "json",
                url: this.$$.mibb + "GetDeployedMarketInsights",
                data: {
                    marketinsightsid: "",
                    lang: localStorage.getItem("langIH") || 1
                },
                success: data => {
                    if (data.status == "SUCCESS") {
                        if (data.text.length !== 0) {
                            this.optionData = data.text;
                            this.optionDataText = `${this.$$.formatDate(data.text[0].date)}`;
                            this.GetDeployedMarketInsights(data.text[0].marketinsightsid);
                        } else {
                            this.fvData = false;
                        }
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        GetDeployedMarketInsights: function (marketinsightsid) {
            this.contentData = [];
            this.emailContentData = [];
            $.ajax({
                type: "POST",
                dataType: "json",
                url: this.$$.mibb + "GetDeployedMarketInsights",
                data: {
                    marketinsightsid: marketinsightsid,
                    lang: localStorage.getItem("langIH") || 1
                },
                success: data => {
                    if (data.status == "SUCCESS") {
                        if (data.text.length !== 0) {
                            this.textName = data.text[0].topic;
                            // console.log(data.text);
                            $.each(data.text, (i, c) => {
                                this.contentData.push({
                                    nameData: [
                                        {
                                            name: this.$t("Und.WT"),
                                            namef: "Warrant Type",
                                            data: c.type
                                        },
                                        {
                                            name: this.$t("hp.CP"),
                                            namef: "Current Price",
                                            data:
                                                c.BID !== "N/A"
                                                    ? this.$$.curreryRetrun(c.underlying_curr) +
                                                    " " +
                                                    this.$$.formatNumber(c.BID, 3, 1)
                                                    : "N/A"
                                        },
                                        {
                                            name: this.$t("WSe.EP"),
                                            namef: "Exercise Price",
                                            data:
                                                c.exercise_price !== "N/A"
                                                    ? this.$$.curreryRetrun(c.underlying_curr) +
                                                    " " +
                                                    this.$$.formatNumber(c.exercise_price, 3, 1)
                                                    : "N/A"
                                        },
                                        {
                                            name: this.$t("WSe.ER"),
                                            namef: "Exercise Ratio",
                                            data:
                                                c.conv_ratio !== "N/A"
                                                    ? this.$$.formatNumber(c.conv_ratio, 3, 1)
                                                    : "N/A"
                                        },
                                        {
                                            name: this.$t("WSe.Mo"),
                                            namef: "Moneyness",
                                            data: c.moneyness_c !== "N/A" ? c.moneyness_c : "N/A"
                                        },
                                        {
                                            name: this.$t("int.Pr"),
                                            namef: "Premium",
                                            data:
                                                c.premium !== "N/A"
                                                    ? this.$$.formatNumber(c.premium, 1, 1) + " %"
                                                    : "N/A",
                                            top: 80,
                                            left: 0
                                        },
                                        {
                                            name: this.$t("int.EG"),
                                            namef: "Effective Gearing",
                                            data:
                                                c.effective_gearing !== "N/A"
                                                    ? this.$$.formatNumber(c.effective_gearing, 1, 1) +
                                                    " x"
                                                    : "N/A",
                                            top: 70,
                                            left: 0
                                        },
                                        {
                                            name: this.$t("hp.Ex"),
                                            namef: "Expiry",
                                            data: `${this.$$.formatDate(
                                                c.maturity
                                            )} (${this.intervalDate(
                                                new Date(c.maturity.replace(/\-/g, "/")),
                                                new Date()
                                            )})`
                                        }
                                    ],
                                    overallData: c
                                });
                                this.emailContentData.push({
                                    effective_gearing:
                                        c.effective_gearing !== "N/A"
                                            ? this.$$.formatNumber(c.effective_gearing, 1, 1) + " x"
                                            : "N/A",
                                    conv_ratio:
                                        c.conv_ratio !== "N/A"
                                            ? this.$$.formatNumber(c.conv_ratio, 3, 1)
                                            : "N/A",
                                    maturity: `${this.$$.formatDate(
                                        c.maturity
                                    )} (${this.intervalDate(new Date(c.maturity), new Date())})`,
                                    exercise_price:
                                        c.exercise_price !== "N/A"
                                            ? this.$$.curreryRetrun(c.underlying_curr) +
                                            " " +
                                            this.$$.formatNumber(c.exercise_price, 3, 1)
                                            : "N/A",
                                    description: c.description,
                                    dsply_nmll: c.dsply_nmll,
                                    type: c.type,
                                    premium:
                                        c.premium !== "N/A"
                                            ? this.$$.formatNumber(c.premium, 1, 1) + " %"
                                            : "N/A",
                                    topic: c.topic,
                                    moneyness_c: c.moneyness_c !== "N/A" ? c.moneyness_c : "N/A",
                                    BID: c.BID
                                });
                            });
                        }
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        optionShow: function () {
            $("#Watch" + this.Windex)
                .find(".option")
                .slideToggle();
        },
        // 时间计算
        intervalDate: function (old, news) {
            return this.$$.formatNumber(
                (old - news) / (1 * 24 * 60 * 60 * 1000) / 30,
                0,
                0
            ) < 0
                ? "Expired Warrant"
                : this.$$.formatNumber(
                    (old - news) / (1 * 24 * 60 * 60 * 1000) / 30,
                    0,
                    0
                ) + " months";
        },
        selectContent: function (data) {
            $("#Watch" + this.Windex)
                .find(".option")
                .hide();
            this.optionDataText = `${this.$$.formatDate(data.date)}`;
            this.GetDeployedMarketInsights(data.marketinsightsid);
        }
    },
    components: {
        Chart2
    }
};
