import Matrix from "./components/Matrix/Matrix.vue";
import Highlights from "./components/Highlights/Highlights.vue";
import Movers from "./components/Movers/Movers.vue";
import FTSE from "./components/FTSE/FTSE.vue";
import New from "./components/New/New.vue";
import Expired from "./components/Expired/Expired.vue";
import Watch from "./components/Watch/Watch.vue";
import Bulletin from "./components/Bulletin/Bulletin.vue";
import HK from "./components/HK/HK.vue";
import Telegram from "./components/Telegram/Telegram.vue";
export default {
    name: "Home",
    created () {
        if (
            localStorage.getItem("disclaimerchoice") == null ||
            localStorage.getItem("disclaimerchoice") == ""
        ) {
            $("#DisclaimerModal").modal("show");
        } else {
            var today = new Date().getTime();
            var result = (today - localStorage.getItem("disclaimerchoice")) / 3600000;
            if (Number(result) > 336) {
                $("#DisclaimerModal").modal("show");
            }
        }
        this.MonitorScreen();
        this.$$.navbarAnimation();
        this.$nextTick(function () {
            // localStorage.clear()
            // localStorage.removeItem('mibbHome')
            // 获得ric的数据
            this.GetScreenerData();
            this.GetDeployedLandingBanner();
            // banner的搜索框出现
            this.ariseCombobox("#comboboxBanner1");
            this.ariseCombobox("#comboboxBanner2");
            this.$$.inputEmpty(".banner_input");
            if (JSON.parse(localStorage.getItem("mibbHome")) !== null) {
                this.Decimal = JSON.parse(localStorage.getItem("mibbHome"));
                $.each(this.Decimal, (i, c) => {
                    if (c.id == "lm") {
                        c.name = this.$t("nav.LM");
                    }
                    if (c.id == "dh") {
                        c.name = this.$t("hp.TP");
                    }
                    if (c.id == "wm") {
                        c.name = this.$t("nav.WM");
                    }
                    if (c.id == "ftse") {
                        c.name = this.$t("hp.FW");
                    }
                    if (c.id == "new") {
                        c.name = this.$t("hp.NW");
                    }
                    if (c.id == "ew") {
                        c.name = this.$t("hp.EW");
                    }
                    // if (c.id == "Warrants Watch") {
                    //     c.name = this.$t("nav.WW");
                    // }
                    // if (c.id == "Warrants Bulletin") {
                    //     c.name = this.$t("nav.WB");
                    // }
                    //   if (c.id == "Telegram") {
                    //     c.name = this.$t("hp.TE");
                    //   }
                });
            }
        });
    },
    mounted () {
        this.$nextTick(function () { });
    },
    data () {
        return {
            // 数据类型的显示
            Decimal: [
                {
                    name: this.$t("nav.LM"),
                    id: "lm"
                },
                {
                    name: this.$t("hp.TP"),
                    id: "dh"
                },
                {
                    name: this.$t("nav.WM"),
                    id: "wm"
                },
                {
                    name: this.$t("hp.FW"),
                    id: "ftse"
                },
                {
                    name: this.$t("hp.NW"),
                    id: "new"
                },
                {
                    name: this.$t("hp.EW"),
                    id: "ew"
                }
            ],
            // 数据类型菜单
            DecimalMenu: [
                {
                    name: this.$t("nav.LM"),
                    id: "lm"
                },
                {
                    name: this.$t("hp.TP"),
                    id: "dh"
                },
                {
                    name: this.$t("nav.WM"),
                    id: "wm"
                },
                {
                    name: this.$t("hp.FW"),
                    id: "ftse"
                },
                {
                    name: this.$t("hp.NW"),
                    id: "new"
                },
                {
                    name: this.$t("hp.EW"),
                    id: "ew"
                },
                // {
                //     name: this.$t("nav.WW"),
                //     id: "ww"
                // },
                // {
                //     name: this.$t("nav.WB"),
                //     id: "wb"
                // },
                // {
                //     name: 'HK Stock Warrants',
                //     id: 'hk'
                // },
                // {
                //     name: this.$t("hp.TE"),
                //     id: "Te"
                // }
            ],
            // list 和 underlyings ric的数据
            listAnUnderlyingsData: [],
            // list 和 underlyings ric的数据的备份
            listAnUnderlyingsDataDackups: [],
            // list和underlyings ric 的下拉菜单 input的文字显示
            listAnUnderlyingsText: "",
            // list和underlyings ric 的下拉菜单 ric
            listAnUnderlyingsRic: "",
            cilcestimate: "",
            cilckric: "",
            // list和underlyings ric 的下拉菜单的显示
            listAnUnderlyingsIf: false,
            bammerImg: []
        };
    },
    computed: {},
    watch: {},
    methods: {
        // 获取Banner的数据
        GetDeployedLandingBanner: function () {
            let _this = this;
            $.ajax({
                type: "post",
                url: this.$$.mibb + "GetDeployedLandingBanner",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        $.each(data.text, function (i, img) {
                            if (img) {
                                _this.bammerImg.push(img);
                            }
                        });
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 underlyings ric
        GetScreenerData: function () {
            let _this = this;
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetScreenerData",
                data: { token: "webkey", init: "1" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        $.each(data.underlyings, function (i, c) {
                            _this.listAnUnderlyingsData.push({
                                name: `${c.underlying_code} (${c.underlying_name})`,
                                ric: c.underlying_ric,
                                visible: [
                                    c.underlying_ticker,
                                    c.underlying_code,
                                    c.underlying_name
                                ],
                                estimate: false
                            });
                        });

                        this.getRiclist();
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        /*获取ric list*/
        getRiclist: function () {
            let _this = this;
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetRICList",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        $.each(data.ric, function (i, c) {
                            _this.listAnUnderlyingsData.push({
                                name: `${c.dsply_nmll} (${c.ticker}): ${c.issuer_name.replace(
                                    "MACQ",
                                    "Macquarie"
                                )}`,
                                ric: c.ric,
                                visible: [
                                    c.dsply_nmll,
                                    c.ticker,
                                    c.issuer_name.replace("MACQ", "Macquarie")
                                ],
                                estimate: true
                            });
                        });
                        this.listAnUnderlyingsDataDackups = this.listAnUnderlyingsData;
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // list和underlyings ric 的下拉菜单 消失
        listAnUnderlyingsVanish: function () {
            this.listAnUnderlyingsIf = false;
        },
        // list和underlyings ric 的下拉菜单的搜索
        listAnUnderlyingsSeek: function (e) {
            this.listAnUnderlyingsText = e.target.value;
            if (e.target.value.length == 0) {
                this.listAnUnderlyingsData = this.listAnUnderlyingsDataDackups;
                this.listAnUnderlyingsIf = false;
            } else {
                this.listAnUnderlyingsData = this.$$.filtration(
                    this.listAnUnderlyingsDataDackups,
                    e.target.value
                );
                this.listAnUnderlyingsIf = true;
            }
            const NewJoinRIC = this.listAnUnderlyingsData.filter(item => {
                if (item.visible[0] && item.visible[1] && item.visible[2] && e.target) {
                    return (
                        e.target.value.toLowerCase() === item.visible[0].toLowerCase() ||
                        e.target.value.toLowerCase() === item.visible[1].toLowerCase() ||
                        e.target.value.toLowerCase() === item.visible[2].toLowerCase()
                    );
                }
            });
            if (NewJoinRIC.length == 1) {
                this.enterSeekAssignment(NewJoinRIC[0].estimate, NewJoinRIC[0]);
                this.cilcestimate = NewJoinRIC[0].estimate;
                this.cilckric = NewJoinRIC[0].ric;
            }
        },
        // list和underlyings ric 的下拉菜单 回车事件
        enterSeekAssignment: function (estimate, ric) {
            $("#LandingPage").keyup(event => {
                if (event.keyCode == 13) {
                    if (estimate) {
                        this.$$.toRelevantPage(estimate, ric.visible[0]);
                    } else {
                        this.$$.toRelevantPage(estimate, ric.ric);
                    }
                }
            });
        },
        // list和underlyings ric 的下拉菜单 点击事件
        listAnUnderlyingsClick: function (data) {
            this.listAnUnderlyingsText = data.name;
            this.listAnUnderlyingsRic = data.ric;
            this.$$.inputEmpty(".banner_input");
            this.listAnUnderlyingsIf = false;
            if (data.estimate) {
                this.$$.toRelevantPage(data.estimate, data.visible[0]);
            } else {
                this.$$.toRelevantPage(data.estimate, data.ric);
            }
        },
        // 点击显示相应页面数据
        componentsShow: function (data, index) {
            this.Decimal[index] = data;
            this.$forceUpdate();
            localStorage.removeItem("mibbHome");
            localStorage.setItem("mibbHome", JSON.stringify(this.Decimal));
        },
        // 监听屏幕
        MonitorScreen: function () {
            if ($(window).width() <= 992) {
                this.JudgeIos = true;
            } else {
                this.JudgeIos = false;
            }
            $(window).resize(() => {
                if ($(window).width() <= 992) {
                    this.JudgeIos = true;
                } else {
                    this.JudgeIos = false;
                }
            });
        },
        // 出现combobox
        ariseCombobox: function (id, index) {
            let _this = this;
            this.$$.jqcombobox(id);
            $(id).combobox({
                select: function (event, ui) {
                    console.log(id);
                    if (id == "#comboboxBanner1") {
                        _this.popover1 = this.value;
                    } else {
                        _this.popover2 = this.value;
                    }
                }
            });
            $(id)
                .parent()
                .find("input")
                .attr("readonly", "readonly");
        }
    },
    components: {
        Matrix,
        Highlights,
        Movers,
        FTSE,
        New,
        Expired,
        Watch,
        Bulletin,
        HK,
        Telegram
    }
};
