import Chart from "../Chart/Chart.vue";
export default {
    name: "FTSE",
    created() {
        this.$nextTick(function() {
            this.GetRICListByUnderlying()

        });
    },
    mounted() {

    },
    props: ['Findex', 'JudgeIos'],
    data() {
        return {
            FTSEBursaMalaysiaKLCIIndexData: [],
            chartNmae: '',
            ricName: ''
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取 underlying 的数据
        GetRICListByUnderlying: function() {
            let _this = this
            let arrRic = []
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetRICListByUnderlying",
                data: { token: "webkey", issuers: 'MST', underlying: 'SET50' },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        $.each(data.ric, function(i, c) {
                            arrRic.push(c.ric)
                        })
                        this.getRicdatas(arrRic)
                    }
                },
                error: function(error) {
                    console.log(error);
                }
            });
        },
        /*获取当前ric数据*/
        getRicdatas: function(ric) {
            this.FTSEBursaMalaysiaKLCIIndexData = []
            let _this = this
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetMarketData",
                data: { token: "webkey", ric: ric.toString(), type: "warrantdata" },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        let _this = this
                        $.each(ric, function(i, c) {
                            _this.ricName = `(${data[c].underlying_ticker}) (${data[c].underlying_code}) ${data[c].underlying_name}`
                            _this.chartNmae = data[c].underlying_ticker
                            _this.FTSEBursaMalaysiaKLCIIndexData.push(data[c])
                        })
                    }
                },
                error: function(error) {
                    console.log(error);
                }
            });
        },
    },
    components: {
        Chart
    }
};